import { defineComponent } from 'vue';
import { useAppStore } from '@/store';
const store = useAppStore();
export default defineComponent({
  props: {
    items: []
  },
  data() {
    return {};
  },
  methods: {
    toRichText(item, index) {
      let id = store.getRouter().query;
      let data = {
        query: {
          pathId: id.pathId,
          ppid: index
        }
      };
      const url = this.$router.resolve({
        path: item.jump_link,
        query: data.query
      });
      store.setRouter(data);
      window.open(url.href);
    }
  }
});