import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    items: []
  },
  data() {
    return {};
  },
  methods: {
    lows(id) {
      this.$emit('lows', id);
    }
  }
});