import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import ptitle from '../../components/pageTitle/index.vue';
import smallList from './components/smallList/index.vue';
import middleList from './components/middleList/index.vue';
import bigList from './components/bigList/index.vue';
import mapAndList from './components/mapAndList/index.vue';
import timeAxis from './components/timeAxis/index.vue';
import * as api from '@/api';
import { useAppStore } from '@/store';
const store = useAppStore();
export default defineComponent({
  components: {
    ptitle,
    smallList,
    middleList,
    bigList,
    mapAndList,
    timeAxis
  },
  data() {
    return {
      route: useRoute(),
      title: '',
      img: '',
      item: [],
      subName: '',
      name: '',
      picture: "",
      href: this.$route.path,
      adShow: false,
      modelShow: 1,
      //大图详情数据
      itemDetailMessage: [],
      move: false,
      bigItem: [],
      moveNum: 0,
      query: []
    };
  },
  async created() {
    this.getMessage(); //优先加载bigListModel的数据是因为，使用这个模板使用的数据获取函数不同于其他，所以先加载，不然会出现空页面的情况
    this.record();
    // this.query = this.route.router.query
    // if (!this.query) {//这样做的原因是因为页面的参数都是通过临时缓存传递的，当页面刷新后，临时数据会被清除，所以当用户在模版页面刷新，此时临时数据被刷新掉了的时候，就无法获取当前页的数据，所以跳回首页，让用户自己点击导航栏跳转
    //     this.$router.push({ path: '/' })
    // } else {
    this.getPageListAndImg();
    this.showModel();
    // }
  },

  watch: {
    '$route': 'getPageListAndImg'
  },
  methods: {
    record() {
      // if(this.route.router.query){
      //     this.query = this.route.router.query
      //     return
      // }
      const query = store.getRouter().query;
      if (query) {
        this.query = query;
      } else if (window.location.href.length > 30) {
        let href = window.location.href;
        let query = {
          // adNum: Number(href.substring(href.indexOf('adNum=') + 6)),
          // adShow: Number(href.substring(href.indexOf('&adShow=') + 8, href.indexOf('&adNum='))),
          listType: Number(href.substring(href.indexOf('&listType=') + 10)),
          pathId: Number(href.substring(href.indexOf('pathId=') + 7, href.indexOf('&listType=')))
        };
        this.query = query;
      } else {
        this.$router.push({
          path: '/'
        });
      }
    },
    toSpecialInfoDetial(items) {
      let query = store.getRouter().query;
      let data = {
        query: {
          species_id: items.species_id,
          phase: items.phase,
          name: items.phase_name
        }
      };
      store.setRouter(data);
      this.$router.push({
        path: '/specialInfoDetial'
      });
      // window.open(url.href);
    },

    showModel() {
      let adShow = this.query.adShow;
      if (adShow == 0) {
        this.adShow = false;
      } else {
        this.adShow = true;
      }
      let listType = this.query.listType;
      this.modelShow = listType;
      this.$forceUpdate(); //强制刷新一下
    },

    async getMessage() {
      // const res = await api.getPageMessage(this.$route.query.pathId as any)
      try {
        const rr = await api.getMiniPic({
          data_type: 'DIM'
        });
        this.bigItem = rr.data.list;
      } catch (ee) {
        console.log(ee);
      }
      // console.error( this.$route.query.pathId);
    },

    async getItemMessage(id) {
      this.move = true;
      try {
        const res = await api.getMiniPicDetail({
          species_id: id
        });
        this.itemDetailMessage = res.data.phase_list;
      } catch (e) {
        console.error(e);
      }
    },
    closePop() {
      this.move = false;
    },
    async getPageListAndImg() {
      this.record();
      this.closePop();
      // let cc = this.query.adNum
      // if (cc != '' || cc != undefined) {
      //     cc = this.query.adNum
      // } else {
      //     cc = 25
      // }
      try {
        // const img:any = await api.getAdImgAndVideo(cc)
        // this.img = img.data.ad_list[0].pic
        const item = await api.getPageMessage(this.query.pathId);
        this.item = item.data.content_list;
        this.subName = item.data.name;
        this.title = item.data.name;
      } catch (e) {
        console.error();
      }
      this.showModel();
    }
  }
});