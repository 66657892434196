import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "body_s XhorizontalCenter"
};
const _hoisted_2 = {
  class: "species XhorizontalCenter"
};
const _hoisted_3 = {
  class: "species_newBox"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "cc Allmiddle"
};
const _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "species_newBox_box",
      key: index
    }, [_createElementVNode("img", {
      class: "ppic",
      src: item.species_img
    }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("button", {
      onClick: $event => _ctx.lows(item.id)
    }, _toDisplayString(item.alias), 9, _hoisted_6)])]);
  }), 128))])])]);
}