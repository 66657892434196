import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06406326"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "newItemBox_blueBox Allmiddle"
};
const _hoisted_3 = {
  class: "newItemBox_weightLine"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "newItemBox_blueBox1 Allmiddle"
};
const _hoisted_7 = {
  class: "newItemBox_weightLine1"
};
const _hoisted_8 = {
  class: "title1"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "flex1 directionLeft"
};
const _hoisted_11 = {
  class: "flex1"
};
const _hoisted_12 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "newItemBox",
      key: index
    }, [index % 2 == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, "0" + _toDisplayString(index + 1), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, "0" + _toDisplayString(index + 1), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1)])])), _createElementVNode("div", {
      class: "messageBox flexX",
      style: _normalizeStyle(index % 2 != 0 ? 'direction:rtl' : ''),
      onClick: $event => _ctx.toRichText(item, index)
    }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, "  " + _toDisplayString(item.intro), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("img", {
      class: "imgs",
      src: item.litpic
    }, null, 8, _hoisted_12)])], 12, _hoisted_9)]);
  }), 128);
}